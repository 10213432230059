import React from "react";

import { Space } from "antd";

import { PartnerRecord } from "@/types";
import { FormStyles } from "@/types/responses";

interface PartnerOverviewProps {
  partner: PartnerRecord;
  layoutConfig: FormStyles;
  getCardStyle: (
    additionalStyle?: Record<string, string | number | undefined>
  ) => Record<string, string | number | undefined>;
  renderFieldSection: (
    title: string,
    items: string[] | undefined
  ) => React.ReactNode;
  renderSingleField: (
    title: string,
    value: string | undefined
  ) => React.ReactNode;
}

export const PartnerOverview: React.FC<PartnerOverviewProps> = ({
  partner,
  layoutConfig,
  getCardStyle,
  renderFieldSection,
  renderSingleField,
}) => {
  // Extract services from custom fields
  const services = partner.custom_fields
    ?.filter((field) => field.name?.includes("Service"))
    ?.map((field) => field.multi_select_value)
    .flat();

  // Extract project budgets from custom fields
  const projectBudgets = partner.custom_fields?.find((field) =>
    field.name?.includes("Budget")
  )?.select_value;

  // Extract language from custom fields
  const language = partner.custom_fields
    ?.filter((field) => field.name?.includes("Language"))
    ?.map((field) => field.multi_select_value)
    .flat();

  // Explicitly filter out tag: "directory listing"
  const filteredTags = partner.tags?.filter(
    (tag) => !tag.Name?.toLowerCase().includes("directory listing")
  );

  return (
    <div>
      <h2>Partner Overview</h2>
      <div
        style={{
          borderTop: ".5px solid #f0f0f0",
          width: "100%",
          paddingTop: 24,
        }}
      />
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {/* Services */}
        {renderFieldSection("Services", services?.filter(Boolean) as string[])}

        {/* Project Budgets */}
        {renderSingleField("Project Budgets", projectBudgets)}

        {/* Language */}
        {renderFieldSection("Language", language?.filter(Boolean) as string[])}

        {/* Location / Region */}
        {renderFieldSection(
          "Location / Region",
          partner.regions
            ?.map((region) => region.Name)
            .filter(Boolean) as string[]
        )}

        {/* Partner Type */}
        {renderFieldSection(
          "Partner Type",
          filteredTags?.map((type) => type.Name).filter(Boolean) as string[]
        )}
      </Space>
    </div>
  );
};
