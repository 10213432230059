import type { IResourceItem } from "@refinedev/core";

import {
  BookOpen,
  Brain,
  ChartLineUp,
  Clipboard,
  CurrencyCircleDollar,
  Database,
  Flask,
  Gauge,
  Handshake,
  Link,
  Lock,
  UserGear,
} from "@phosphor-icons/react";

const common_resources_for_admins: IResourceItem[] = [
  {
    name: "referral-links",
    list: "/referral-links",
    edit: "/referral-links/edit/:id",
    create: "/referral-links/create",
    meta: {
      label: "Referral Links",
      icon: <Link size={16} />,
      dataProviderName: "propelApi",
    },
  },
];

const common_resources: IResourceItem[] = [
  {
    name: "companies",
    show: "/companies/show/:id",
    meta: {
      hide: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "contacts",
    show: "/contacts/show/:id",
    edit: "/contacts/edit/:id",
    meta: {
      hide: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "leads",
    meta: {
      hide: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "administration",
    meta: {
      label: "Administration",
      icon: <UserGear size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "settings",
    list: "/administration/settings",
    meta: {
      label: "Company Settings",
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "settings_team",
    edit: "/administration/settings/team/edit/:id",
    meta: {
      hide: true,
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
  /* because it's commented in the routes.tsx */
  /*
  {
    name: "reports",
    list: "/reports",
    create: "/reports/create",
    edit: "/reports/edit/:id",
    meta: {
      label: "Reports",
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
  */
  {
    name: "logs",
    list: "/administration/logs",
    meta: {
      label: "Logs",
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "catalog",
    list: "/connections/catalog",
    meta: {
      label: "Integrations",
      parent: "administration",
      dataProviderName: "propelApi",
    },
  },
  // {
  //   name: "report-schedules",
  //   list: "/report-schedules",
  //   edit: "/report-schedules/edit/:id",
  //   create: "/report-schedules/create",
  //   meta: {
  //     label: "Report Schedules",
  //     parent: "administration",
  //     dataProviderName: "propelApi",
  //   },
  // },
  {
    name: "document_viewer",
    show: "/documents/:id",
    meta: {
      hide: true,
      dataProviderName: "propelApi",
    },
  },
];

const super_admin_resources: IResourceItem[] = [
  {
    name: "experimental",
    list: "/experimental",
    meta: {
      label: "Experimental",
      icon: <Flask size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "admin-base",
    meta: {
      label: "Super Admin",
      icon: <Lock size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "prompts",
    list: "/prompts",
    edit: "/prompts/edit/:id",
    create: "/prompts/create",
    meta: {
      label: "Manage Prompts",
      parent: "admin-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "superadmin_companies",
    list: "/superadmin/companies",
    edit: "/superadmin/companies/edit/:id",
    meta: {
      label: "Manage Companies",
      parent: "admin-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "feature_flags",
    list: "/feature_flags",
    edit: "/feature_flags/edit/:id",
    create: "/feature_flags/create",
    meta: {
      label: "Manage Features",
      parent: "admin-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "debug",
    list: "/debug",
    meta: {
      label: "Debug",
      parent: "admin-base",
      dataProviderName: "propelApi",
    },
  },
  // there are two route entries for this
  // one is in the partner.routes.tsx
  // one is in the company.routes.tsx
  {
    name: "ai_assistant",
    list: "/ai_assistant",
    meta: {
      label: "AI Assistant",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
];

export const TEMPLATE_DOCUMENT_RESOURCE: IResourceItem = {
  name: "template_document",
  list: "/partners/contracts/templates",
  create: "/partners/contracts/templates/create",
  meta: {
    label: "Templates Documents",
    parent: "partners-base",
    canDelete: true,
    hide: true,
    dataProviderName: "propelApi",
  },
};

export const DOCUMENT_SIGNING_RESOURCE: IResourceItem = {
  name: "document_signings",
  list: "/partners/contracts/signings",
  create: "/partners/contracts/signings/create",
  meta: {
    label: "Document Signings",
    parent: "partners-base",
    canDelete: true,
    hide: true,
    dataProviderName: "propelApi",
  },
};

export const DOCUMENT_FOLDER_RESOURCE: IResourceItem = {
  name: "document_folders",
  list: "/document_folders",
  create: "/document_folders/create",
  edit: "/document_folders/edit/:id",
  meta: {
    label: "Folders",
    canDelete: true,
    hide: true,
    dataProviderName: "propelApi",
  },
};
// PRO-627
const company_resources_for_admins: IResourceItem[] = [
  {
    name: "partners_directory",
    list: "/partners_directory",
    create: "/partners_directory/create",
    edit: "/partners_directory/edit/:id",
    // show: "/partners_directory/show/:id",
    meta: {
      label: "Partner Directory",
      parent: "partners-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "prospect",
    list: "/partners/prospecting",
    meta: {
      label: "Prospecting",
      parent: "partners-base",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },

  {
    name: "automations",
    list: "/automations",
    create: "/automations/create",
    edit: "/automations/edit/:id",
    meta: {
      label: "AI Agents",
      icon: <Brain size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "tasks",
    list: "/tasks",
    create: "/tasks/create",
    edit: "/tasks/edit/:id",
    meta: {
      label: "Tasks",
      canDelete: true,
      icon: <Clipboard size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "feedback",
    list: "/feedback",
    meta: {
      label: "Feedback",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "email_campaign",
    list: "/email_campaign",
    meta: {
      label: "Email campaign",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },

  {
    name: "tracks",
    list: "/enablement/tracks",
    edit: "/enablement/tracks/edit/:id",
    show: "/enablement/tracks/show/:id",
    create: "/enablement/tracks/create",
    meta: {
      label: "Tracks",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "payouts",
    list: "/commissions/payouts",
    edit: "/commissions/payouts/edit/:id",
    meta: {
      label: "Payouts",
      parent: "commissions",
      dataProviderName: "propelApi",
    },
  },
];

const company_resources: IResourceItem[] = [
  {
    name: "dashboard",
    list: "/dashboard",
    meta: {
      label: "Dashboard",
      icon: <Gauge size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partners-base",
    meta: {
      label: "Partners",
      icon: <Handshake size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partners",
    list: "/partners",
    edit: "/partners/edit/:id",
    create: "/partners/create",
    meta: {
      label: "Manage Partners",
      parent: "partners-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "statuses",
    create: "/partners/statuses/create",
    edit: "/partners/statuses/edit/:id",
    show: "/partners/statuses/manage",
    meta: {
      hide: true,
    },
  },
  {
    name: "partner-page",
    list: "/partners/pages",
    create: "/partners/pages/create",
    edit: "/partners/pages/edit/:id",
    show: "/partners/pending/opportunities/show/:id",
    meta: {
      label: "Partner Signup",
      canDelete: true,
      parent: "partners-base",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "contracts",
    list: "/partners/contracts",
    create: "/partners/contracts/create",
    meta: {
      label: "Contracts",
      parent: "partners-base",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  DOCUMENT_FOLDER_RESOURCE,
  TEMPLATE_DOCUMENT_RESOURCE,
  DOCUMENT_SIGNING_RESOURCE,

  {
    name: "meetings",
    list: "/partners/meetings",
    meta: {
      hide: true,
      label: "Meetings",
      parent: "partners-base",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "commissions",
    meta: {
      label: "Revenue",
      icon: <ChartLineUp size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "opportunities",
    list: "/commissions/opportunities",
    create: "/commissions/opportunities/create",
    show: "/commissions/opportunities/show/:id",
    meta: {
      label: "Opportunities",
      parent: "commissions",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "opportunity_notes",
    edit: "/commissions/opportunities/notes/:id",
    meta: {
      hide: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "referrals",
    list: "/referral-pages",
    create: "/referral-pages/create",
    edit: "/referral-pages/edit/:id",
    show: "/referral-pages/opportunities/show/:id",
    meta: {
      label: "Deal Reg Forms",
      canDelete: true,
      parent: "commissions",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "structure",
    list: "/commissions/structure",
    edit: "/commissions/structure/edit/:id",
    meta: {
      label: "Incentive Structures",
      parent: "commissions",
      dataProviderName: "propelApi",
    },
  },

  {
    name: "enablement",
    meta: {
      label: "Enablement",
      icon: <BookOpen size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "resources",
    list: "/resources",
    meta: {
      label: "Resources",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "training",
    list: "/training",
    create: "/training/create",
    edit: "/training/edit/:id",
    meta: {
      label: "Training",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "entitlements",
    list: "/enablement/entitlements",
    edit: "/enablement/entitlements/edit/:id",
    show: "/enablement/entitlements/show/:id",
    create: "/enablement/entitlements/create",
    meta: {
      hide: true,
      label: "Entitlements",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "data-management",
    meta: {
      label: "Data Manager",
      icon: <Database size={16} />,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partner-types",
    list: "/partners/types",
    create: "/partners/types/create",
    edit: "/partners/types/edit/:id",
    show: "/partners/types/show/:id",
    meta: {
      label: "Partner Object",
      parent: "data-management",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "regions",
    list: "/partners/regions",
    create: "/partners/regions/create",
    edit: "/partners/regions/edit/:id",
    show: "/partners/regions/show/:id",
    meta: {
      label: "Partner Regions",
      parent: "data-management",
      canDelete: true,
      dataProviderName: "propelApi",
      referenceType: "Partner",
    },
  },
  {
    name: "contact-types",
    list: "/contacts/types",
    create: "/contacts/types/create",
    edit: "/contacts/types/edit/:id",
    show: "/contacts/types/show/:id",
    meta: {
      label: "Contact Object",
      parent: "data-management",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "opportunity-types",
    list: "/commissions/opportunities/types",
    create: "/commissions/opportunities/types/create",
    edit: "/commissions/opportunities/types/edit/:id",
    show: "/commissions/opportunities/types/show/:id",
    meta: {
      label: "Opportunity Object",
      parent: "data-management",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "custom-fields-external",
    list: "/partners/fields/external",
    meta: {
      label: "External Mapping",
      parent: "data-management",
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
];

// PRO-627
const partner_resources_for_admin: IResourceItem[] = [
  {
    name: "partner-tracks",
    list: "/enablement/partner-tracks",
    edit: "/enablement/partner-tracks/edit/:id",
    show: "/enablement/partner-tracks/show/:id",
    create: "/enablement/partner-tracks/create",
    meta: {
      label: "Tracks",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "payouts",
    list: "/revenue/payouts",
    meta: {
      label: "Payouts",
      parent: "revenue",
      dataProviderName: "propelApi",
    },
  },
];

const partner_resources: IResourceItem[] = [
  {
    name: "partner-dashboard",
    list: "/partner-dashboard",
    meta: {
      label: "Dashboard",
      icon: <Gauge size={16} />,
      dataProviderName: "propelApi",
    },
  },
  // {
  //   name: "clients-base",
  //   meta: {
  //     label: "Clients",
  //     icon: <UserList size={16} />,
  //     canDelete: true,
  //     dataProviderName: "propelApi",
  //   },
  // },
  // commented the client realted routes PRO-628
  // {
  //   name: "clients",
  //   list: "/clients",
  //   edit: "/clients/edit/:id", // pages aren't implemented yet
  //   show: "/clients/show/:id", // not used, instead /companies/show/3 is used
  //   create: "/clients/create",
  //   meta: {
  //     label: "Manage Clients",
  //     parent: "clients-base",
  //     dataProviderName: "propelApi",
  //   },
  // },
  // {
  //   name: "mapping",
  //   list: "/clients/mapping",
  //   meta: {
  //     label: "Team",
  //     parent: "clients-base",
  //     dataProviderName: "propelApi",
  //   },
  // },
  {
    name: "revenue",
    meta: {
      label: "Revenue",
      icon: <CurrencyCircleDollar size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "deal-desk",
    list: "/revenue/deal-desk",
    show: "/revenue/deal-desk/show/:id",
    create: "/revenue/deal-desk/create",
    meta: {
      label: "Deal Desk",
      parent: "revenue",
      dataProviderName: "propelApi",
    },
  },

  {
    name: "enablement",
    meta: {
      label: "Enablement",
      icon: <BookOpen size={16} />,
      canDelete: true,
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partner-resources",
    list: "/partner-resources",
    meta: {
      label: "Resources",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "partner-training",
    list: "/partner-training",
    meta: {
      label: "Training",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
  {
    name: "entitlements",
    list: "/enablement/partner-entitlements",
    edit: "/enablement/partner-entitlements/edit/:id",
    show: "/enablement/partner-entitlements/show/:id",
    create: "/enablement/partner-entitlements/create",
    meta: {
      hide: true,
      label: "Entitlements",
      parent: "enablement",
      dataProviderName: "propelApi",
    },
  },
];

function getPartnerResources(isSuperAdmin: boolean) {
  return isSuperAdmin
    ? [...partner_resources, ...partner_resources_for_admin]
    : partner_resources;
}

function getCompanyResources(isSuperAdmin: boolean) {
  return isSuperAdmin
    ? [...company_resources, ...company_resources_for_admins]
    : company_resources;
}

function getCommonResources(isSuperAdmin: boolean) {
  return isSuperAdmin
    ? [...common_resources_for_admins, ...common_resources]
    : common_resources;
}

export function getResources({
  mode,
  isSuperAdmin,
}: {
  mode: string;
  isSuperAdmin: boolean;
}) {
  let resources =
    mode === "Company"
      ? getCompanyResources(isSuperAdmin)
      : getPartnerResources(isSuperAdmin);
  resources = resources.concat(getCommonResources(isSuperAdmin));

  if (isSuperAdmin) {
    resources = resources.concat(super_admin_resources);
  }
  return resources;
}
