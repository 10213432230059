import React, { useCallback, useMemo, useRef, useState } from "react";

import { DateField } from "@refinedev/antd";
import { useGo, useInfiniteList, useOne, useUpdate } from "@refinedev/core";

import Icon from "@ant-design/icons";
import {
  ArrowSquareOut,
  Bell,
  Check,
  EnvelopeSimple,
} from "@phosphor-icons/react";
import {
  Badge,
  Button,
  Card,
  Divider,
  Flex,
  List,
  Popover,
  Segmented,
  theme,
  Typography,
} from "antd";
import dayjs from "dayjs";

import { Notification, UnreadCount } from "@/types";

const { Text, Title } = Typography;

const NotificationList: React.FC<{
  notifications: Notification[];
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  handleNotificationClick: (item: Notification) => void;
}> = ({ notifications, handleNotificationClick }) => {
  const { token } = theme.useToken();

  const today = dayjs();
  const todayNotifications = notifications.filter((n) =>
    dayjs(n.created_at).isSame(today, "day")
  );
  const olderNotifications = notifications.filter(
    (n) => !dayjs(n.created_at).isSame(today, "day")
  );

  return (
    <>
      {notifications.length === 0 ? (
        <Flex
          vertical
          align="center"
          justify="center"
          style={{
            padding: token.paddingLG,
            color: token.colorTextSecondary,
          }}
        >
          <EnvelopeSimple size={24} weight="light" />
          <Text type="secondary">No notifications</Text>
        </Flex>
      ) : (
        <>
          {todayNotifications.length > 0 && (
            <>
              <div
                style={{
                  paddingTop: token.padding,
                }}
              >
                <Text type="secondary">NEW</Text>
              </div>
              <List
                dataSource={todayNotifications}
                renderItem={(item) => (
                  <NotificationItem
                    notification={item}
                    handleNotificationClick={handleNotificationClick}
                  />
                )}
              />
              {olderNotifications.length > 0 && (
                <Divider
                  style={{
                    margin: 0,
                  }}
                  dashed
                />
              )}
            </>
          )}
          {olderNotifications.length > 0 && (
            <>
              <div
                style={{
                  paddingTop: token.padding,
                }}
              >
                <Text type="secondary">OLDER</Text>
              </div>
              <List
                dataSource={olderNotifications}
                renderItem={(item) => (
                  <NotificationItem
                    notification={item}
                    handleNotificationClick={handleNotificationClick}
                  />
                )}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export const NotificationBell: React.FC = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [activeSegment, setActiveSegment] = useState<"all" | "unread">("all");

  const { token } = theme.useToken();
  const go = useGo();

  const { data: unreadCount, refetch } = useOne<UnreadCount>({
    resource: "in_app_notifications",
    id: "unread_count",
  });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch: refetchNotifications,
  } = useInfiniteList<Notification>({
    resource: "in_app_notifications",
    pagination: {
      pageSize: 5,
      mode: "server",
    },
  });

  const notifications = useMemo(() => {
    return ([] as Notification[]).concat(
      ...(data?.pages ?? []).map((page) => page.data)
    );
  }, [data?.pages]);

  const { mutateAsync: markAllAsRead } = useUpdate({
    resource: `in_app_notifications`,
    id: "mark_all_as_read",
    values: {},
    successNotification: false,
  });
  const onMarkAllNotificationsAsRead = useCallback(async () => {
    markAllAsRead().then(() => {
      refetch();
      refetchNotifications();
    });
  }, [markAllAsRead, refetch, refetchNotifications]);

  const { mutateAsync: markAsRead } = useUpdate({
    resource: `in_app_notifications`,
    id: "mark_as_read",
    successNotification: false,
  });

  const onMarkNotificationsAsRead = useCallback(
    async (notificationIds: string[]) => {
      markAsRead({
        values: {
          notification_ids: notificationIds,
        },
      }).then(() => {
        refetch();
        refetchNotifications();
      });
    },
    [markAsRead, refetch, refetchNotifications]
  );

  const handleNotificationClick = async (item: Notification) => {
    if (!item.is_read) {
      await onMarkNotificationsAsRead([item.id]);
    }
    if (item.link) {
      if (item.link.startsWith("/")) {
        setPopoverOpen(false);
        go({
          to: item.link,
          type: "push",
        });
      } else {
        window.open(item.link, "_blank");
      }
    }
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  }, []);

  const handleLoadMore = useCallback(() => {
    fetchNextPage().then(() => {
      setTimeout(scrollToBottom, 100);
    });
  }, [fetchNextPage, scrollToBottom]);

  const totalCount = useMemo(() => {
    return data?.pages[data?.pages.length - 1]?.total ?? 0;
  }, [data?.pages]);

  const notificationContent = (
    <Card
      style={{
        width: 300,
      }}
      styles={{
        body: {
          padding: token.paddingSM,
        },
      }}
    >
      <Flex vertical style={{ maxHeight: 450 }}>
        <Title level={5}>Notifications</Title>
        <Segmented
          block
          value={activeSegment}
          onChange={(value) => setActiveSegment(value as "all" | "unread")}
          options={[
            {
              label: (
                <span>
                  All <Text type="secondary">{totalCount}</Text>
                </span>
              ),
              value: "all",
            },
            {
              label: (
                <span>
                  Unread{" "}
                  <Text type="secondary">
                    {unreadCount?.data.unread_count || 0}
                  </Text>
                </span>
              ),
              value: "unread",
            },
          ]}
        />

        <div ref={scrollContainerRef} style={{ flex: 1, overflowY: "auto" }}>
          <NotificationList
            notifications={
              activeSegment === "all"
                ? notifications
                : notifications.filter((n) => !n.is_read)
            }
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            handleNotificationClick={handleNotificationClick}
          />
        </div>

        <Flex
          justify="space-between"
          align="center"
          style={{
            paddingTop: token.padding,
            borderTop: `1px solid ${token.colorBorder}`,
          }}
        >
          <Button
            size="small"
            type="text"
            icon={<Check size={14} />}
            onClick={onMarkAllNotificationsAsRead}
            disabled={
              totalCount === 0 || (unreadCount?.data.unread_count || 0) === 0
            }
            style={{
              color: token.colorTextSecondary,
            }}
          >
            Mark all as read
          </Button>
          <Button
            size="small"
            type="primary"
            loading={isFetchingNextPage}
            onClick={handleLoadMore}
            disabled={!hasNextPage}
            ghost
          >
            Load more
          </Button>
        </Flex>
      </Flex>
    </Card>
  );

  return (
    <Popover
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      trigger="click"
      content={notificationContent}
      placement="bottomLeft"
    >
      <Badge
        count={
          (unreadCount?.data.unread_count || 0) > 99
            ? "99+"
            : unreadCount?.data.unread_count
        }
        size="small"
      >
        <Icon component={() => <Bell size={16} />} />
      </Badge>
    </Popover>
  );
};

type NotificationItemProps = {
  notification: Notification;
  handleNotificationClick: (item: Notification) => void;
  allRead?: boolean;
};

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  handleNotificationClick,
}) => {
  const { token } = theme.useToken();

  return (
    <List.Item
      onClick={() => {
        handleNotificationClick(notification);
      }}
      style={{
        cursor: "pointer",
      }}
    >
      <Flex vertical gap={token.marginXS}>
        <Flex align="center" gap={token.marginXS}>
          <Text>{notification.text}</Text>
          {notification.link && (
            <ArrowSquareOut
              size={14}
              style={{ color: token.colorTextSecondary }}
            />
          )}
        </Flex>
        <DateField
          value={notification.created_at}
          format="MMMM d, YYYY · h:mm A"
          style={{
            color: token.colorTextSecondary,
          }}
        />
      </Flex>
      {!notification.is_read && (
        <Badge status="warning" style={{ marginRight: token.marginLG }} />
      )}
    </List.Item>
  );
};
