import React, { useEffect, useState } from "react";

import { Select, Space, Typography } from "antd";

import { PartnerRecord } from "@/types";
import { PartnerDirectoryConfig } from "@/types/responses";

const { Text } = Typography;

// Generic option type that works with both string and number values
type FilterOption<T extends string | number> = {
  label: string;
  value: T;
};

// Props for the reusable FilterSelect component
interface FilterSelectProps<T extends string | number> {
  title: string;
  placeholder: string;
  selectedValues: T[];
  setSelectedValues: (values: T[]) => void;
  options: FilterOption<T>[];
}

// Reusable FilterSelect component
const FilterSelect = <T extends string | number>({
  title,
  placeholder,
  selectedValues,
  setSelectedValues,
  options,
}: FilterSelectProps<T>) => {
  return (
    <div>
      <Text strong style={{ display: "block", marginBottom: 8, fontSize: 16 }}>
        {title}
      </Text>
      <Select
        mode="multiple"
        allowClear
        placeholder={placeholder}
        style={{ width: "100%" }}
        value={selectedValues}
        onChange={setSelectedValues}
        options={options}
      />
    </div>
  );
};

// Define a filter values interface to represent all filter states
export interface FilterValues {
  selectedRegions: number[];
  selectedPartnerTypes: number[];
  // Add dynamic custom field filters
  customFieldFilters: Record<string, string[]>;
}

interface DirectoryFiltersProps {
  directory?: PartnerDirectoryConfig;
  customFilter: Record<string, string[]>;
  uniqueRegions: { label: string; value: number }[];
  uniquePartnerTypes: { label: string; value: number }[];
  // Initial filter values (optional)
  initialFilters?: Partial<FilterValues>;
  // Callback when any filter changes
  onFilterChange?: (filters: FilterValues) => void;
  // Optional partners data for internal filtering
  partners?: PartnerRecord[];
  // Optional search text for filtering
  searchText?: string;
}

export const DirectoryFilters: React.FC<DirectoryFiltersProps> = ({
  directory,
  customFilter,
  uniqueRegions,
  uniquePartnerTypes,
  initialFilters,
  onFilterChange,
  partners,
  searchText,
}) => {
  const [selectedRegions, setSelectedRegions] = useState<number[]>(
    initialFilters?.selectedRegions || []
  );
  const [selectedPartnerTypes, setSelectedPartnerTypes] = useState<number[]>(
    initialFilters?.selectedPartnerTypes || []
  );
  // Add state for custom field filters
  const [customFieldFilters, setCustomFieldFilters] = useState<
    Record<string, string[]>
  >(initialFilters?.customFieldFilters || {});

  // Call onFilterChange whenever any filter changes
  useEffect(() => {
    const currentFilters = {
      selectedRegions,
      selectedPartnerTypes,
      customFieldFilters,
    };

    if (onFilterChange) {
      onFilterChange(currentFilters);
    }
  }, [
    selectedRegions,
    selectedPartnerTypes,
    customFieldFilters,
    onFilterChange,
    partners,
    searchText,
  ]);

  const handleRegionsChange = (values: number[]) => {
    setSelectedRegions(values);
  };

  const handlePartnerTypesChange = (values: number[]) => {
    setSelectedPartnerTypes(values);
  };

  // Add handler for custom field filter changes
  const handleCustomFieldChange = (fieldName: string, values: string[]) => {
    setCustomFieldFilters((prev) => ({
      ...prev,
      [fieldName]: values,
    }));
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      {directory?.include_custom_field_filter &&
        Object.entries(customFilter).map(([key, values]) => (
          <FilterSelect<string>
            key={key}
            title={key}
            placeholder={`All ${key}`}
            selectedValues={customFieldFilters[key] || []}
            setSelectedValues={(values) => handleCustomFieldChange(key, values)}
            options={values.map((value) => ({
              label: value,
              value,
            }))}
          />
        ))}

      {directory?.include_region_filter && uniqueRegions.length > 0 && (
        <FilterSelect<number>
          title="Location / Region"
          placeholder="All Regions"
          selectedValues={selectedRegions}
          setSelectedValues={handleRegionsChange}
          options={uniqueRegions}
        />
      )}

      {directory?.include_partner_type_filter &&
        uniquePartnerTypes.length > 0 && (
          <FilterSelect<number>
            title="Partner Type"
            placeholder="All Partner Types"
            selectedValues={selectedPartnerTypes}
            setSelectedValues={handlePartnerTypesChange}
            options={uniquePartnerTypes}
          />
        )}
    </Space>
  );
};
