import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useOne } from "@refinedev/core";

import { Col, Flex, Row, Tag, theme, Typography } from "antd";

import { PartnerRecord } from "@/types";
import { FormStyles, PartnerDirectoryConfig } from "@/types/responses";

import {
  PartnerDescription,
  PartnerHeader,
  PartnerIntegrationDetails,
  PartnerOverview,
  ShowCaseReviews,
} from "./components";

const { Title } = Typography;

export const PartnerDetailsPage: React.FC = () => {
  const { directoryId, partnerId } = useParams();
  const { token } = theme.useToken();

  // Fetch directory configuration
  const { data: directoryData } = useOne<PartnerDirectoryConfig>({
    resource: "partners_directory/public",
    id: directoryId as string,
    dataProviderName: "propelApi",
  });

  // Fetch partner details
  const { data: partnerData } = useOne<PartnerRecord>({
    resource: "partners_directory/partners/public",
    id: `${directoryId}/${partnerId}`,
    dataProviderName: "propelApi",
  });

  const directory = directoryData?.data;
  const partner = partnerData?.data;

  // Layout configuration from form_styles
  const layoutConfig = useMemo<FormStyles>(() => {
    if (!directory?.page_styles) {
      return {
        layout: "vertical", // Default layout
        size: "large",
        align: "center",
        variant: "outlined", // Default variant
      };
    }

    return {
      layout: directory.page_styles.layout || "vertical",
      size: directory.page_styles.size || "large",
      align: directory.page_styles.align || "center",
      variant: directory.page_styles.variant || "outlined",
    };
  }, [directory?.page_styles]);

  if (!partner || !partner.partner_company) {
    return <div>Loading...</div>;
  }

  // Determine spacing and sizing based on form_styles
  const getContainerStyle = () => {
    const baseStyle = { background: token.colorBgLayout, minHeight: "100vh" };

    switch (layoutConfig.size) {
      case "small":
        return { ...baseStyle, padding: "20px 16px" };
      case "middle":
        return { ...baseStyle, padding: "30px 20px" };
      case "large":
        return { ...baseStyle, padding: "40px 24px" };
      default:
        return { ...baseStyle, padding: "40px 24px" };
    }
  };

  const getContentStyle = () => {
    const baseStyle = { maxWidth: 1200, margin: "0 auto" };

    // Apply alignment
    switch (layoutConfig.align) {
      case "start":
        return { ...baseStyle, margin: "0 auto 0 0" };
      case "end":
        return { ...baseStyle, margin: "0 0 0 auto" };
      case "center":
        return { ...baseStyle, margin: "0 auto" };
      default:
        return baseStyle;
    }
  };

  // Get style based on variant
  const getCardStyle = (
    additionalStyle: Record<string, string | number | undefined> = {}
  ) => {
    const baseStyle = { ...additionalStyle };

    // Apply variant-specific styling
    switch (layoutConfig.variant) {
      case "borderless":
        return {
          ...baseStyle,
          borderColor: "transparent",
          boxShadow: "none",
        };
      case "filled":
        return {
          ...baseStyle,
          background: token.colorFillSecondary,
        };
      case "outlined":
        return {
          ...baseStyle,
          border: `1px solid ${token.colorBorder}`,
        };
      default:
        return baseStyle;
    }
  };

  const getTagStyle = () => {
    const baseStyle = {
      margin: "4px",
      padding: "4px 8px",
      color: token.colorTextSecondary,
    };

    // Apply variant-specific styling
    switch (layoutConfig.variant) {
      case "borderless":
        return {
          ...baseStyle,
          border: "none",
          background: token.colorBgLayout,
        };
      case "filled":
        return { ...baseStyle, background: token.colorFillSecondary };
      case "outlined":
      default:
        return baseStyle;
    }
  };

  // Render field section based on layout style
  const renderFieldSection = (title: string, items: string[] | undefined) => {
    if (!items || items.length === 0) {
      return null;
    }

    if (layoutConfig.layout === "horizontal") {
      // Horizontal layout for field values
      return (
        <div style={{ marginBottom: 16, color: token.colorTextSecondary }}>
          <Title level={5}>{title}</Title>
          <Flex wrap="wrap" gap="small">
            {items.map((item) => (
              <Tag key={item} style={getTagStyle()}>
                {item}
              </Tag>
            ))}
          </Flex>
        </div>
      );
    }

    // Default vertical layout for field values
    return (
      <div>
        <Title level={5}>{title}</Title>
        <ul style={{ paddingLeft: 20, color: token.colorTextSecondary }}>
          {items.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    );
  };

  // Render single field based on layout style
  const renderSingleField = (title: string, value: string | undefined) => {
    if (!value) {
      return null;
    }

    if (layoutConfig.layout === "horizontal") {
      return (
        <div style={{ marginBottom: 16, color: token.colorTextSecondary }}>
          <Title level={5}>{title}</Title>
          <Tag style={getTagStyle()}>{value}</Tag>
        </div>
      );
    }

    return (
      <div>
        <Title level={5}>{title}</Title>
        <ul style={{ paddingLeft: 20, color: token.colorTextSecondary }}>
          <li>{value}</li>
        </ul>
      </div>
    );
  };

  return (
    <div style={getContainerStyle()}>
      <div style={getContentStyle()}>
        {/* Header Section */}
        <PartnerHeader partner={partner} />

        {/* Description Section */}
        <PartnerDescription partner={partner} />

        {/* Divider line */}
        <div
          style={{
            borderTop: ".5px solid #f0f0f0",
            width: "100%",
            paddingBottom: "24px",
          }}
        />

        <Row gutter={[24, 24]}>
          {/* Left Column - Partner Overview */}
          <Col xs={24} md={8}>
            <PartnerOverview
              partner={partner}
              layoutConfig={layoutConfig}
              getCardStyle={getCardStyle}
              renderFieldSection={renderFieldSection}
              renderSingleField={renderSingleField}
            />
          </Col>

          {/* Right Column - Integration Details & Reviews */}
          <Col xs={24} md={16}>
            {/* Integration Details */}
            <PartnerIntegrationDetails
              partner={partner}
              layoutConfig={layoutConfig}
              getCardStyle={getCardStyle}
            />

            {/* Reviews */}
            <ShowCaseReviews
              layoutConfig={layoutConfig}
              getCardStyle={getCardStyle}
              referenceId={Number(partnerId)}
              referenceTable="Partner"
              companyId={partner.partner_company_id}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
