import React from "react";

import { theme, Typography } from "antd";

import { PartnerRecord } from "@/types";

const { Paragraph } = Typography;

interface PartnerDescriptionProps {
  partner: PartnerRecord;
}

export const PartnerDescription: React.FC<PartnerDescriptionProps> = ({
  partner,
}) => {
  const { token } = theme.useToken();

  return (
    <Paragraph
      style={{
        fontSize: 16,
        color: token.colorTextSecondary,
        marginTop: 24,
        marginBottom: 40,
      }}
    >
      {partner.partner_company.Description ||
        "Award for meeting bronze level partner status"}
    </Paragraph>
  );
};
