import { useContext } from "react";

import { Checkbox, Flex, theme, Typography } from "antd";

import { ColorModeContext, ColorModeOption } from "@/contexts/color-mode";

const { useToken } = theme;

// Color constants
enum Colors {
  LIGHT_BACKGROUND = "#FAFAFA",
  DARK_BACKGROUND = "#141414",
  LIGHT_INNER = "white",
  DARK_INNER = "black",
  LIGHT_BORDER = "rgb(217, 217, 217)",
  DARK_BORDER = "rgb(66, 66, 66)",
}

// Common styles
const STYLES = {
  APPEARANCE_ITEM: {
    width: 82,
    height: 70,
    position: "relative" as const,
    overflow: "hidden",
    cursor: "pointer",
  },
  CHECKBOX_WRAPPER: {
    position: "absolute" as const,
    bottom: "2px",
    right: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  CHECKBOX: {
    pointerEvents: "none" as const,
    margin: 0,
    padding: 0,
  },
  HALF_PANEL: {
    width: "50%",
    height: "100%",
    paddingTop: "16px",
  },
  INNER_TEXT_PANEL: {
    height: "100%",
    paddingLeft: "8px", // Using token.padding in component
  },
} as const;

type AppearanceOption = ColorModeOption;

interface AppearanceItemProps {
  value: AppearanceOption;
  isSelected: boolean;
  onClick: () => void;
}

const AppearanceItem: React.FC<AppearanceItemProps> = ({
  value,
  isSelected,
  onClick,
}) => {
  const { token } = useToken();

  return (
    <div
      style={{
        ...STYLES.APPEARANCE_ITEM,
        border: `${token.lineWidth * 2}px solid ${
          isSelected ? token.colorPrimary : token.colorBorder
        }`,
        borderRadius: token.borderRadiusLG,
        background:
          value === "light" ? Colors.LIGHT_BACKGROUND : Colors.DARK_BACKGROUND,
      }}
      onClick={onClick}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          borderRadius: token.borderRadius,
          background:
            value === "light" ? Colors.LIGHT_INNER : Colors.DARK_INNER,
          position: "absolute",
          top: "16px",
          left: "20px",
          border: `1px solid ${
            value === "light" ? Colors.LIGHT_BORDER : Colors.DARK_BORDER
          }`,
          paddingLeft: token.padding,
        }}
      >
        <Typography.Text
          style={{
            color: value === "dark" ? Colors.LIGHT_INNER : Colors.DARK_INNER,
          }}
        >
          Aa
        </Typography.Text>
      </div>
      {isSelected && (
        <div style={STYLES.CHECKBOX_WRAPPER}>
          <Checkbox checked style={STYLES.CHECKBOX} />
        </div>
      )}
    </div>
  );
};

const SystemAppearanceItem: React.FC<AppearanceItemProps> = ({
  isSelected,
  onClick,
}) => {
  const { token } = useToken();

  return (
    <div
      style={{
        ...STYLES.APPEARANCE_ITEM,
        border: `${token.lineWidth * 2}px solid ${
          isSelected ? token.colorPrimary : token.colorBorder
        }`,
        borderRadius: token.borderRadiusLG,
      }}
      onClick={onClick}
    >
      <div style={{ height: "100%", display: "flex", gap: 0 }}>
        <div
          style={{
            ...STYLES.HALF_PANEL,
            background: Colors.DARK_BACKGROUND,
          }}
        >
          <div
            style={{
              ...STYLES.INNER_TEXT_PANEL,
              background: Colors.DARK_INNER,
              borderTop: `1px solid ${Colors.DARK_BORDER}`,
              paddingLeft: token.padding,
            }}
          >
            <Typography.Text style={{ color: Colors.LIGHT_INNER }}>
              Aa
            </Typography.Text>
          </div>
        </div>
        <div
          style={{
            ...STYLES.HALF_PANEL,
            background: Colors.LIGHT_BACKGROUND,
          }}
        >
          <div
            style={{
              ...STYLES.INNER_TEXT_PANEL,
              background: Colors.LIGHT_INNER,
              borderTop: `1px solid ${Colors.LIGHT_BORDER}`,
              paddingLeft: token.padding,
            }}
          >
            <Typography.Text style={{ color: Colors.DARK_INNER }}>
              Aa
            </Typography.Text>
          </div>
        </div>
      </div>
      {isSelected && (
        <div style={STYLES.CHECKBOX_WRAPPER}>
          <Checkbox checked style={STYLES.CHECKBOX} />
        </div>
      )}
    </div>
  );
};

export const AppearanceSelector = () => {
  const { token } = useToken();
  const { colorMode, setColorModeOption } = useContext(ColorModeContext);
  return (
    <Flex gap={token.margin} wrap="wrap">
      <Flex vertical align="center" gap={token.marginXS}>
        <AppearanceItem
          value={ColorModeOption.Light}
          isSelected={colorMode === ColorModeOption.Light}
          onClick={() => setColorModeOption(ColorModeOption.Light)}
        />
        <Typography.Text>Light</Typography.Text>
      </Flex>
      <Flex vertical align="center" gap={token.marginXS}>
        <AppearanceItem
          value={ColorModeOption.Dark}
          isSelected={colorMode === ColorModeOption.Dark}
          onClick={() => setColorModeOption(ColorModeOption.Dark)}
        />
        <Typography.Text>Dark</Typography.Text>
      </Flex>
      <Flex vertical align="center" gap={token.marginXS}>
        <SystemAppearanceItem
          value={ColorModeOption.System}
          isSelected={colorMode === ColorModeOption.System}
          onClick={() => setColorModeOption(ColorModeOption.System)}
        />
        <Typography.Text>System</Typography.Text>
      </Flex>
    </Flex>
  );
};
