import React from "react";

import { UrlField } from "@refinedev/antd";

import { Col, Rate, Row, Space, Typography } from "antd";

import { CustomAvatar, Text } from "@/components";
import { PartnerRecord } from "@/types";
import { getNameInitials } from "@/utilities";

const { Title } = Typography;

interface PartnerHeaderProps {
  partner: PartnerRecord;
}

export const PartnerHeader: React.FC<PartnerHeaderProps> = ({ partner }) => {
  // Get rating from custom fields
  const rating = partner.custom_fields?.find((field) =>
    field.name?.includes("Rating")
  )?.decimal_value;

  // Get the URL for the "Get In Touch" button
  const getContactUrl = () => {
    // Get custom link field if it exists
    const customLinkField = partner.custom_fields?.find(
      (field) => field.name === "Custom Link" || field.name === "Website Link"
    );

    // Use custom link if available, otherwise use default domain link
    return customLinkField?.string_value
      ? customLinkField.string_value
      : `https://${partner.partner_company.Domain}`;
  };

  return (
    <Row gutter={[24, 24]} align="top">
      <Col xs={24} md={16}>
        <Space size={24} align="start">
          <CustomAvatar
            size={"large"}
            src={partner.partner_company.PhotoUrl}
            name={getNameInitials(partner?.partner_company.CompanyName || "")}
            style={{
              fontSize: 80,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "none",
            }}
          />
          <div>
            <Title level={2} style={{ marginBottom: 8 }}>
              {partner.partner_company.CompanyName}
            </Title>
            <Rate disabled defaultValue={rating} style={{ fontSize: 16 }} />
            <Text style={{ marginLeft: 8 }}>{rating}/5</Text>
          </div>
        </Space>
      </Col>
      <Col xs={24} md={8} style={{ textAlign: "right" }}>
        <UrlField
          children="Get in touch"
          value={getContactUrl()}
          target="_blank"
          style={{ fontSize: "18px", fontWeight: 500 }}
        />
      </Col>
    </Row>
  );
};
