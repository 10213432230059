import {
  useActiveAuthProvider,
  useLogout,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";

import { SignOut } from "@phosphor-icons/react";
import { Button } from "antd";

export const LogoutButton = () => {
  const translate = useTranslate();
  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const authProvider = useActiveAuthProvider();
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });

  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(
        translate(
          "warnWhenUnsavedChanges",
          "Are you sure you want to leave? You have unsaved changes."
        )
      );

      if (confirm) {
        setWarnWhen(false);
        mutateLogout();
      }
    } else {
      mutateLogout();
    }
  };

  return (
    <Button type="text" icon={<SignOut size={16} />} onClick={handleLogout}>
      {translate("buttons.logout", "Logout")}
    </Button>
  );
};
