import { FC, memo } from "react";

import { Avatar as AntdAvatar, AvatarProps, Tooltip } from "antd";

import { getNameInitials, getRandomColorFromString } from "@/utilities";

type Props = Omit<AvatarProps, "src"> & {
  name?: string;
  useToolTip?: boolean;
  src?: string;
};
const IMAGE_STYLES: React.CSSProperties = {
  width: "100%",
  height: "100%",
  objectFit: "contain",
};
const CustomAvatarComponent: FC<Props> = ({
  name = "",
  useToolTip = false,
  size = "small",
  style,
  ...rest
}) => {
  const img = rest?.src ? <img src={rest.src} style={IMAGE_STYLES} /> : null;

  const avatar = (
    <AntdAvatar
      alt={name}
      size={size}
      style={{
        backgroundColor: rest?.src
          ? "transparent"
          : getRandomColorFromString(name),
        display: "flex",
        alignItems: "center",
        border: "none",
        ...style,
      }}
      {...rest}
      src={img}
    >
      {getNameInitials(name)}
    </AntdAvatar>
  );

  if (useToolTip) {
    return <Tooltip title={name}>{avatar}</Tooltip>;
  }

  return avatar;
};

export const CustomAvatar = memo(
  CustomAvatarComponent,
  (prevProps, nextProps) => {
    return prevProps.name === nextProps.name && prevProps.src === nextProps.src;
  }
);
