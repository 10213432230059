import React from "react";

import { Typography } from "antd";

import { PartnerRecord } from "@/types";
import { FormStyles } from "@/types/responses";

const { Paragraph } = Typography;

interface PartnerIntegrationDetailsProps {
  partner: PartnerRecord;
  layoutConfig: FormStyles;
  getCardStyle: (
    additionalStyle?: Record<string, string | number | undefined>
  ) => Record<string, string | number | undefined>;
}

export const PartnerIntegrationDetails: React.FC<
  PartnerIntegrationDetailsProps
> = ({ partner, layoutConfig, getCardStyle }) => {
  return (
    <div>
      <h2>Integration Details</h2>
      <div
        style={{
          borderTop: ".5px solid #f0f0f0",
          width: "100%",
          paddingTop: 24,
        }}
      />

      <Paragraph>
        {partner.partner_company.Description ||
          "Award for meeting bronze level partner status."}
      </Paragraph>
    </div>
  );
};
