import {
  createContext,
  PropsWithChildren,
  startTransition,
  useEffect,
  useState,
} from "react";

import { ConfigProvider, theme } from "antd";

const { darkAlgorithm, defaultAlgorithm, compactAlgorithm } = theme;

type ColorModeContextType = {
  colorMode: ColorModeOption;
  setColorModeOption: (option: ColorModeOption) => void;
};

export enum ColorModeOption {
  Light = "light",
  Dark = "dark",
  System = "system",
}

export const ColorModeContext = createContext<ColorModeContextType>(
  {} as ColorModeContextType
);

const getSystemColorMode = () => {
  const isSystemPreferenceDark = window?.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  return isSystemPreferenceDark ? ColorModeOption.Dark : ColorModeOption.Light;
};

const getInitialColorMode = () => {
  const colorModeFromLocalStorage = localStorage.getItem(
    "colorMode"
  ) as ColorModeOption;
  return colorModeFromLocalStorage || ColorModeOption.System;
};

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [selectedColorModeOption, setSelectedColorModeOption] =
    useState<ColorModeOption>(getInitialColorMode());

  const [colorMode, setMode] = useState(
    selectedColorModeOption === ColorModeOption.System
      ? getSystemColorMode()
      : selectedColorModeOption
  );

  const [motion, setMotion] = useState(true);

  useEffect(() => {
    setMotion(false);
    setMode(
      selectedColorModeOption === ColorModeOption.System
        ? getSystemColorMode()
        : selectedColorModeOption
    );
    startTransition(() => {
      setMotion(true);
    });
  }, [selectedColorModeOption]);

  useEffect(() => {
    window.localStorage.setItem("colorMode", selectedColorModeOption);
  }, [selectedColorModeOption]);

  useEffect(() => {
    document.body.style.backgroundColor =
      colorMode === ColorModeOption.Light ? "#FFFFFF" : "#000000";
  }, [colorMode]);

  return (
    <ColorModeContext.Provider
      value={{
        colorMode: selectedColorModeOption,
        setColorModeOption: setSelectedColorModeOption,
      }}
    >
      <ConfigProvider
        theme={{
          cssVar: true,
          token: {
            colorPrimary: "#F26D0F",
            fontFamily: "Inter",
            wireframe: true,
            colorLink: "#F26D0F",
            ...(motion
              ? {}
              : {
                  motionDurationFast: "0",
                  motionDurationMid: "0",
                  motionDurationSlow: "0",
                }),
            colorBgLayout: colorMode === "light" ? "#FAFAFA" : "#000000",
          },
          components: {
            Card: {},
            Menu: {
              itemSelectedBg:
                colorMode === "light"
                  ? "HSL(190, 5%, 93%)"
                  : "HSL(190, 5%, 10%)", // Light grey for light mode, darker grey for dark mode
              itemSelectedColor: colorMode === "light" ? "#000000" : "#FFFFFF",
              itemHoverBg:
                colorMode === "light"
                  ? "HSL(190, 5%, 90%)"
                  : "HSL(190, 5%, 40%)", // Very light for hover in light mode, medium-dark grey for dark mode
              itemActiveBg:
                colorMode === "light"
                  ? "HSL(190, 5%, 80%)"
                  : "HSL(190, 5%, 25%)", // Slightly lighter for active in light mode, darker for dark mode
              subMenuItemBg: "transparent", // Very light for hover in light mode, medium-dark grey for dark mode
            },
            Button: {
              defaultShadow: "none",
              primaryShadow: "none",
            },
            Tree: {
              directoryNodeSelectedBg:
                colorMode === "light"
                  ? "HSL(190, 5%, 85%)"
                  : "HSL(190, 5%, 30%)", // Light grey for light mode, darker grey for dark mode
            },
            Table: {
              headerBorderRadius: 0,
            },
            Badge: {
              dotSize: 8,
              statusSize: 8,
            },
          },
          algorithm: [
            colorMode === "light" ? defaultAlgorithm : darkAlgorithm,
            compactAlgorithm,
          ],
        }}
      >
        {children}
      </ConfigProvider>
    </ColorModeContext.Provider>
  );
};
