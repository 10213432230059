export const formatAddress = (address: any): string => {
  if (!address) return "";

  const { address_line_1, address_line_2, city, state, country, postal_code } =
    address;

  // Convert the structured address to a single plain string
  // Following the same format as UI
  return [address_line_1, address_line_2, city, state, country, postal_code]
    .filter(Boolean) // Filter out any undefined or empty values
    .join(", ");
};
