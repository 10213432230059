import { useContext, useEffect, useState } from "react";

import { RefineLayoutThemedTitleProps } from "@refinedev/antd";
import { useGetIdentity, useOne } from "@refinedev/core";

import Icon from "@ant-design/icons";
import { Gear } from "@phosphor-icons/react";
import { Avatar, Card, Divider, Flex, Popover, Select, Typography } from "antd";
import dayjs from "dayjs";

import { NotificationBell } from "@/components/in-app-notifications";
import { AppModeContext } from "@/contexts/app-context";
import { useUserPreference } from "@/providers/identity";
import type { Identity as User } from "@/types";
import { ICompanyConfiguration } from "@/types/company_configuration";

import { AppearanceSelector } from "./appearance-selector";
import { LogoutButton } from "./logout";

const { Text } = Typography;

const setDayJSLocale = async (region?: string) => {
  switch (region) {
    case "en-ca":
      await import("dayjs/locale/en-ca");
      dayjs.locale("en-ca");
      break;
    case "en-gb":
      await import("dayjs/locale/en-gb");
      dayjs.locale("en-gb");
      break;
    case "en":
    default:
      dayjs.locale("en");
  }
};

// Add new AppearanceSelector component

export const Header: React.FC<RefineLayoutThemedTitleProps> = ({
  collapsed,
}) => {
  const [openSettingsPopover, setOpenSettingsPopover] = useState(false);

  const { data: user } = useGetIdentity<User>();
  const preferences = useUserPreference();

  const { mode: productMode, setMode: setProductMode } =
    useContext(AppModeContext);

  useEffect(() => {
    if (
      preferences &&
      preferences.user_mode_state &&
      preferences.user_mode_state !== productMode
    ) {
      setProductMode(preferences.user_mode_state);
    }
  }, [preferences, productMode, setProductMode]);

  const { data } = useOne<ICompanyConfiguration>({
    resource: "company_settings",
    id: "config",
  });

  useEffect(() => {
    setDayJSLocale(data?.data?.region);
  }, [data?.data?.region]);

  return (
    <Flex align="center" justify="flex-start" gap={8}>
      {user?.imageUrl && (
        <Avatar
          src={user?.imageUrl}
          alt={user?.fullName ?? ""}
          style={{ flexShrink: 0 }}
          size={21}
        />
      )}

      {!collapsed && (
        <Flex gap={4} align="center" flex={1} justify="space-between">
          {user?.fullName && !collapsed && (
            <Text strong ellipsis={{ tooltip: true }} style={{ maxWidth: 120 }}>
              {user.fullName}
            </Text>
          )}

          <Flex gap={8} align="center">
            <NotificationBell />

            <Popover
              placement="bottomLeft"
              content={
                <Card title="Settings" style={{ width: 300 }} bordered={false}>
                  <Flex vertical gap={16}>
                    <Flex vertical gap={8}>
                      <Text type="secondary">VIEWS</Text>
                      <Select
                        value={productMode}
                        onChange={(value) => setProductMode(value)}
                        options={[
                          { label: "Company View", value: "Company" },
                          { label: "Partner View", value: "Partner" },
                        ]}
                        style={{ width: "100%" }}
                      />
                    </Flex>
                    <Divider dashed style={{ margin: 0 }} />
                    <Flex vertical gap={8}>
                      <Text type="secondary">APPEARANCE</Text>
                      <AppearanceSelector />
                    </Flex>
                    <Divider dashed style={{ margin: 0 }} />
                    <Flex>
                      <LogoutButton />
                    </Flex>
                  </Flex>
                </Card>
              }
              trigger="click"
              open={openSettingsPopover}
              onOpenChange={setOpenSettingsPopover}
            >
              <Icon component={() => <Gear size={16} />} />
            </Popover>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
