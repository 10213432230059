export const RESOURCES = {
  GLOBAL: "Global",
  PERMISSION: "Permission",
  OPPORTUNITY: "Opportunity",
  // ... other resources
};

export const ACTIONS = {
  VIEW: "View",
  MANAGE: "Manage",
  GLOBAL: "Global",
  // ... other actions
};

// Resources that are enabled for access control
// As Refine configures resources based on the resources array.
// So by default, it makes Server call for all resources.
// As we just started onboarding modules to permissions, so that's why we're specifying the resources that are enabled for access control.
// To avoid unnecessary server calls.
export const AUTH_ENABLED_RESOURCES = [
  RESOURCES.PERMISSION,
  RESOURCES.OPPORTUNITY,
];

export const DEFINED_RESOURCES_TO_PERMISSION_RESOURCE_MAP = {
  opportunities: RESOURCES.OPPORTUNITY,
  Permission: RESOURCES.PERMISSION,
  PublicOpportunity: RESOURCES.OPPORTUNITY,
  "deal-desk": RESOURCES.OPPORTUNITY,
};

export const REFINE_ACTIONS_TO_PERMISSION_ACTION_MAP = {
  edit: ACTIONS.MANAGE,
  create: ACTIONS.MANAGE,
  delete: ACTIONS.MANAGE,
  view: ACTIONS.VIEW,
  list: ACTIONS.VIEW,
  show: ACTIONS.VIEW,
};
