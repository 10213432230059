import React from "react";

import type { RefineThemedLayoutV2Props } from "@refinedev/antd";
import { ThemedLayoutContextProvider } from "@refinedev/antd";

import { Layout as AntdLayout, theme } from "antd";

export const ThemedLayoutV2: React.FC<RefineThemedLayoutV2Props> = ({
  children,
  Header,
  Sider,
  Title,
  Footer,
  OffLayoutArea,
  initialSiderCollapsed,
  onSiderCollapsed,
}) => {
  const { token } = theme.useToken();

  const SiderToRender = Sider;
  const HeaderToRender = Header;

  const hasSider = SiderToRender ? !!SiderToRender({ Title }) : false;

  return (
    <ThemedLayoutContextProvider
      initialSiderCollapsed={initialSiderCollapsed}
      onSiderCollapsed={onSiderCollapsed}
    >
      <AntdLayout style={{ minHeight: "100vh" }} hasSider={hasSider}>
        {SiderToRender && <SiderToRender Title={Title} />}
        <AntdLayout>
          {HeaderToRender && <HeaderToRender />}
          <AntdLayout.Content>
            <div
              style={{
                minHeight: `calc(100vh - ${16 * 2}px)`,

                padding: 16,
                margin: 16,
                marginLeft: 0,

                border: `1px solid ${token.colorBorder}`,
                borderRadius: token.borderRadiusLG,

                backgroundColor: token.colorBgContainer,
              }}
            >
              {children}
            </div>
            {OffLayoutArea && <OffLayoutArea />}
          </AntdLayout.Content>
          {Footer && <Footer />}
        </AntdLayout>
      </AntdLayout>
    </ThemedLayoutContextProvider>
  );
};
