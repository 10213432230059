import React from "react";

import { ArrowRightOutlined, TrophyOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Rate,
  Row,
  Space,
  Tag,
  theme,
  Typography,
} from "antd";

import { CustomAvatar, Text } from "@/components";
import { CustomFieldResponse, PartnerRecord } from "@/types";
import { CustomFieldType } from "@/types/custom_field_type";
import { PartnerDirectoryConfig } from "@/types/responses";
import { getNameInitials } from "@/utilities";
import { formatAddress } from "@/utilities/addressUtils";

const { Title } = Typography;

interface PartnersListProps {
  directory?: PartnerDirectoryConfig;
  filteredPartners: PartnerRecord[];
  searchText: string;
  setSearchText: (value: string) => void;
  directoryId?: string;
}

export const PartnersCardList: React.FC<PartnersListProps> = ({
  directory,
  filteredPartners,
  directoryId,
}) => {
  const { token } = theme.useToken();

  const renderFieldValue = (field: CustomFieldResponse) => {
    switch (field.field_type as CustomFieldType) {
      case "MULTI_SELECT":
        return field.multi_select_value?.join(", ") || "-";
      case "SELECT":
        return field.select_value || "-";
      case "DECIMAL":
        return field.decimal_value?.toString() || "-";
      case "BOOL":
        return field.bool_value ? "Yes" : "No";
      case "DATETIME":
        return field.datetime_value?.toLocaleString() || "-";
      case "EMAIL":
        return field.email_value || "-";
      case "PHONE_NUMBER":
        return field.phone_number_value || "-";
      case "ADDRESS":
        return formatAddress(field.address_value) || "-";
      case "REFERENCE":
        return field.reference_value?.name || "-";
      default:
        return field.string_value || "-";
    }
  };

  const renderPartnerCard = (partner: PartnerRecord) => {
    // Check if partner is featured
    const isFeatured = directory?.featured_partner_ids?.includes(partner.ID);

    // Get custom fields to display
    const customFieldsToDisplay = partner.custom_fields?.filter((field) =>
      directory?.display_custom_fields?.includes(field.id)
    );

    // Get rating from custom fields
    const rating = partner.custom_fields?.find((field) =>
      field.name?.includes("Rating")
    )?.decimal_value;

    // Determine column span based on tile size
    const getColSpan = () => {
      switch (directory?.tile_size) {
        case "small":
          return { xs: 24, sm: 12, md: 6 };
        case "large":
          return { xs: 24, sm: 24, md: 12 };
        case "medium":
        default:
          return { xs: 24, sm: 12, md: 8 };
      }
    };

    // Filter out tags that are not "directory listing"
    const filteredTags = partner.tags?.filter(
      (tag) => tag.Name && !tag.Name.toLowerCase().includes("directory listing")
    );

    return (
      <Col {...getColSpan()} key={partner.ID}>
        <div
          style={{
            height: "100%",
            position: "relative",
            paddingTop: isFeatured ? 0 : 40,
          }}
        >
          <Card
            style={{
              height: "100%",
              borderRadius: 8,
              background: token.colorBgContainer,
              position: "relative",
            }}
          >
            {isFeatured && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  background: "transparent",
                  color: "#F8C51B",
                  padding: "8px 16px",
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "bold",
                  borderBottom: ".5px solid #f0f0f0",
                }}
              >
                <TrophyOutlined style={{ marginRight: 8 }} />
                Featured Propel Partner
              </div>
            )}
            <Space
              direction="vertical"
              style={{
                width: "100%",
                marginTop: isFeatured ? 40 : 0,
              }}
              size="middle"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <div>
                  <Title
                    level={5}
                    style={{
                      margin: 0,
                      fontSize: directory?.tile_size === "large" ? 20 : 18,
                      marginBottom: 8,
                    }}
                  >
                    {partner.partner_company?.CompanyName || "-"}
                  </Title>
                </div>
                <CustomAvatar
                  size={"large"}
                  src={partner?.partner_company.PhotoUrl}
                  name={getNameInitials(
                    partner?.partner_company.CompanyName || ""
                  )}
                  style={{
                    width: 32,
                    height: 32,
                    fontSize: directory?.tile_size === "large" ? 24 : 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "none",
                  }}
                />
              </div>

              {/* Display custom fields */}
              {customFieldsToDisplay?.map((field) => (
                <div key={field.id}>
                  {!directory?.show_custom_fields_values && (
                    <Text strong style={{ display: "block", marginBottom: 4 }}>
                      {field.name}
                    </Text>
                  )}
                  <Text>{renderFieldValue(field)}</Text>
                </div>
              ))}

              {/* Partner Types */}
              {filteredTags && filteredTags.length > 0 && (
                <Space wrap>
                  {filteredTags.map((tag) => (
                    <Tag key={tag.ID}>{tag.Name}</Tag>
                  ))}
                </Space>
              )}

              {/* Divider line */}
              <div
                style={{
                  borderTop: ".5px solid #f0f0f0",
                  width: "calc(100% + 32px)",
                  margin: "8px -16px",
                  position: "relative",
                }}
              />

              {/* Rating and See More Button in same line */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 8,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Rate
                    disabled
                    defaultValue={rating || 0}
                    style={{
                      fontSize: directory?.tile_size === "large" ? 16 : 14,
                    }}
                  />
                  <Text style={{ marginLeft: 8 }}>
                    {rating ? `${rating}/5` : "N/A"}
                  </Text>
                </div>

                {/* See More Button */}
                <Button
                  type="default"
                  icon={<ArrowRightOutlined />}
                  onClick={() => {
                    window.open(
                      `/partner_directory/${directoryId}/partner/${partner.ID}`,
                      "_blank"
                    );
                  }}
                  style={{
                    height: "auto",
                    fontWeight: "bold",
                    fontSize: directory?.tile_size === "large" ? 16 : 14,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "4px 12px",
                    borderRadius: 4,
                    background: "transparent",
                  }}
                >
                  See More
                </Button>
              </div>
            </Space>
          </Card>
        </div>
      </Col>
    );
  };

  return (
    <>
      <Row gutter={[24, 24]}>{filteredPartners.map(renderPartnerCard)}</Row>
    </>
  );
};
