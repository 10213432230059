import React from "react";

import type { RefineLayoutThemedTitleProps } from "@refinedev/antd";
import { useLink } from "@refinedev/core";

import { Flex, Typography } from "antd";

import { LogoIcon } from "../logo";

const name = "Propel";

export const AppTitle: React.FC<RefineLayoutThemedTitleProps> = ({
  collapsed,
}) => {
  const Link = useLink();
  if (collapsed) {
    return null;
  }
  return (
    <Flex
      align="center"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      flex={1}
    >
      <Link
        to="/login"
        style={{
          display: "inline-block",
          textDecoration: "none",
        }}
      >
        <Flex
          align="center"
          gap={8}
          style={{
            height: "100%",
          }}
          flex={1}
          justify="center"
        >
          <Flex
            style={{
              height: "24px",
              width: "24px",
              flexShrink: 0,
            }}
          >
            <LogoIcon />
          </Flex>

          {!collapsed && (
            <Flex style={{ overflow: "hidden" }}>
              <Typography.Title
                level={4}
                type="secondary"
                style={{ margin: 0 }}
                ellipsis
              >
                {name}
              </Typography.Title>
            </Flex>
          )}
        </Flex>
      </Link>
    </Flex>
  );
};
