import React from "react";

import { Col, Row, theme, Typography } from "antd";

import { PartnerDirectoryConfig } from "@/types/responses";

const { Title, Paragraph } = Typography;

interface DirectoryHeaderProps {
  directory?: PartnerDirectoryConfig;
}

export const DirectoryHeader: React.FC<DirectoryHeaderProps> = ({
  directory,
}) => {
  const { token } = theme.useToken();

  return (
    <Row gutter={[24, 40]} align="top">
      <Col xs={24} md={16}>
        <div style={{ marginBottom: 24 }}>
          <Title level={1} style={{ fontSize: 48, marginBottom: 16 }}>
            Hire a Propel Certified Consultant
          </Title>
          <Paragraph
            style={{
              fontSize: 16,
              color: token.colorTextSecondary,
              maxWidth: 800,
            }}
          >
            {directory?.description ||
              "Find and connect with certified Propel consultants who can help you achieve your goals."}
          </Paragraph>
        </div>
      </Col>
      <Col xs={24} md={8} style={{ textAlign: "right" }}>
        <svg
          width="200"
          height="200"
          viewBox="0 0 200 200"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            maxWidth: "200px",
            height: "200px",
            marginTop: 20,
            opacity: 0.9,
          }}
        >
          <g transform="translate(100,100)">
            {[0, 45, 90, 135, 180, 225, 270, 315].map((angle) => (
              <path
                key={angle}
                d="M0,-50 L20,-30 L-20,-30 Z"
                fill="#E6E6E6"
                transform={`rotate(${angle})`}
              />
            ))}
            <circle r="6" fill="#E6E6E6" />
          </g>
        </svg>
      </Col>
    </Row>
  );
};
