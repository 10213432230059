import React from "react";

import { useList } from "@refinedev/core";

import { Card, Col, Row, Space, theme, Typography } from "antd";

import { CustomAvatar, Text } from "@/components";
import { Review } from "@/types";
import { FormStyles } from "@/types/responses";

const { Title, Paragraph } = Typography;

interface PartnerReviewsProps {
  layoutConfig: FormStyles;
  getCardStyle: (
    additionalStyle?: Record<string, string | number | undefined>
  ) => Record<string, string | number | undefined>;
  referenceId: number;
  referenceTable: string;
  companyId: number;
}

export const ShowCaseReviews: React.FC<PartnerReviewsProps> = ({
  layoutConfig,
  getCardStyle,
  referenceId,
  referenceTable,
  companyId,
}) => {
  const { token } = theme.useToken();

  const { data } = useList<Review>({
    resource: "reviews_by_reference",
    filters: [
      {
        field: "reference_id",
        operator: "eq",
        value: referenceId,
      },
      {
        field: "reference_table",
        operator: "eq",
        value: referenceTable,
      },
      {
        field: "company_id",
        operator: "eq",
        value: companyId,
      },
    ],
    pagination: {
      mode: "off",
    },
  });

  const reviews = data?.data || [];

  if (reviews.length === 0) {
    return (
      <div>
        <Title level={2} style={{ marginBottom: token.marginMD }}>
          Reviews
        </Title>
        <div
          style={{
            borderTop: `${token.lineWidth}px solid ${token.colorBorderSecondary}`,
            width: "100%",
            paddingTop: token.paddingLG,
          }}
        />
        <Text>No reviews available yet.</Text>
      </div>
    );
  }

  return (
    <div>
      <Title level={2} style={{ marginBottom: token.marginMD }}>
        Reviews
      </Title>
      <div
        style={{
          borderTop: `${token.lineWidth}px solid ${token.colorBorderSecondary}`,
          width: "100%",
          paddingTop: token.paddingLG,
        }}
      />
      <Row gutter={[token.marginLG, token.marginLG]}>
        {reviews.map((review) => (
          <Col xs={24} md={8} key={review.ID}>
            <Card
              bordered={true}
              style={getCardStyle({
                height: 200, // Fixed height for consistency
                backgroundColor: token.colorBgContainer,
                borderRadius: token.borderRadiusLG,
                border: `${token.lineWidth}px solid ${token.colorBorderSecondary}`,
              })}
              bodyStyle={{
                padding: token.paddingLG,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflow: "hidden",
              }}
            >
              <Paragraph
                ellipsis={{
                  rows: 5,
                  expandable: false,
                  tooltip: review.quote,
                }}
                style={{ marginBottom: 0, lineHeight: "1.5715" }}
              >
                {review.quote}
              </Paragraph>
              <Space
                align="center"
                style={{ marginTop: "auto", paddingTop: token.paddingMD }}
              >
                <CustomAvatar
                  src={review.photo}
                  name={review.company}
                  size={40}
                />
                <div>
                  <Text
                    strong
                    style={{
                      display: "block",
                      fontSize: token.fontSize,
                      lineHeight: token.lineHeight,
                      color: token.colorTextHeading,
                    }}
                  >
                    {review.author}
                  </Text>
                  <Text
                    type="secondary"
                    style={{
                      display: "block",
                      fontSize: token.fontSizeSM,
                      lineHeight: token.lineHeightSM,
                    }}
                  >
                    {review.designation} - {review.company}
                  </Text>
                </div>
              </Space>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};
