import { FilterValues } from "@/pages/partner_directory/components/DirectoryFilters";
import { PartnerRecord } from "@/types";

// Function to apply filters to partners
export const applyFilters = (
  partners: PartnerRecord[],
  filters: FilterValues,
  searchText = ""
): PartnerRecord[] => {
  return partners.filter((partner) => {
    // Text search filter
    if (searchText && partner.partner_company?.CompanyName) {
      if (
        !partner.partner_company.CompanyName.toLowerCase().includes(
          searchText.toLowerCase()
        )
      ) {
        return false;
      }
    }

    // Custom field filters
    if (
      filters.customFieldFilters &&
      Object.keys(filters.customFieldFilters).length > 0
    ) {
      for (const [fieldName, selectedValues] of Object.entries(
        filters.customFieldFilters
      )) {
        if (selectedValues.length === 0) continue; // Skip if no values selected for this field

        // Find the custom field by name
        const customField = partner.custom_fields?.find(
          (field) => field.name === fieldName
        );

        // Check if the partner has any of the selected values for this field
        const hasMatchingValue = customField?.multi_select_value?.some(
          (value) => selectedValues.includes(value)
        );

        if (!hasMatchingValue) {
          return false;
        }
      }
    }

    // Region filter
    if (filters.selectedRegions.length > 0) {
      const partnerRegionIds =
        partner.regions?.map((region) => region.ID) || [];
      if (
        !filters.selectedRegions.some((id) => partnerRegionIds.includes(id))
      ) {
        return false;
      }
    }

    // Partner Type filter
    if (filters.selectedPartnerTypes.length > 0) {
      const partnerTypeIds = partner.tags?.map((tag) => tag.ID) || [];
      if (
        !filters.selectedPartnerTypes.some((id) => partnerTypeIds.includes(id))
      ) {
        return false;
      }
    }

    return true;
  });
};
