import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useList, useOne } from "@refinedev/core";

import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row, theme } from "antd";

import { PartnerRecord } from "@/types";
import { PartnerDirectoryConfig } from "@/types/responses";

import {
  DirectoryFilters,
  DirectoryHeader,
  PartnersCardList,
} from "./components";
import { FilterValues } from "./components/DirectoryFilters";
import { applyFilters } from "./utils/filterUtils";

export const PublicPartnerDirectoryPage: React.FC = () => {
  const { directoryId } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>("");
  const [filters, setFilters] = useState<FilterValues>({
    selectedRegions: [],
    selectedPartnerTypes: [],
    customFieldFilters: {},
  });

  const { token } = theme.useToken();

  // Fetch directory configuration
  const { data: directoryData } = useOne<PartnerDirectoryConfig>({
    resource: "partners_directory/public",
    id: directoryId as string,
  });

  // Fetch partners for this directory using the public endpoint
  const { data: partnersData } = useList<PartnerRecord>({
    resource: `partners_directory/partners/public/${directoryId}`,
    pagination: {
      mode: "off",
    },
  });

  const directory = directoryData?.data;
  const partners = partnersData?.data || [];

  // Sort partners to show featured partners first
  const sortedPartners = [...partners].sort((a, b) => {
    const aIsFeatured = directory?.featured_partner_ids?.includes(a.ID);
    const bIsFeatured = directory?.featured_partner_ids?.includes(b.ID);

    if (aIsFeatured && !bIsFeatured) return -1;
    if (!aIsFeatured && bIsFeatured) return 1;
    return 0;
  });

  // Handle filter changes from DirectoryFilters component
  const handleFilterChange = useCallback((newFilters: FilterValues) => {
    setFilters(newFilters);
  }, []);

  // Debounce search text to avoid excessive filtering
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchText]);

  // Memoize the filtered partners to avoid unnecessary recalculations
  const filteredPartners = useMemo(() => {
    return applyFilters(sortedPartners, filters, debouncedSearchText);
  }, [sortedPartners, filters, debouncedSearchText]);

  // Create a map to store unique custom field values for each filter field
  const customFieldsMap: Record<string, Set<string>> = {};
  // Map to store field ID to field name mapping
  const fieldIdToNameMap: Record<number, string> = {};

  // First, collect all field names for the filter custom fields
  partners.forEach((partner) => {
    partner.custom_fields?.forEach((customField) => {
      if (
        directory?.filter_custom_fields?.includes(customField.id) &&
        customField.name
      ) {
        fieldIdToNameMap[customField.id] = customField.name;
      }
    });
  });

  // Initialize the map for each filter custom field using names
  directory?.filter_custom_fields?.forEach((fieldId) => {
    const fieldName = fieldIdToNameMap[fieldId] || `field_${fieldId}`;
    customFieldsMap[fieldName] = new Set<string>();
  });

  // Collect all unique values for each custom field from all partners
  partners.forEach((partner) => {
    partner.custom_fields?.forEach((customField) => {
      // Check if this custom field is one of our filter fields
      if (
        directory?.filter_custom_fields?.includes(customField.id) &&
        customField.multi_select_value &&
        customField.name
      ) {
        const fieldName =
          fieldIdToNameMap[customField.id] || `field_${customField.id}`;
        // Add each value to the set for this field name
        customField.multi_select_value.forEach((value) => {
          if (value) {
            customFieldsMap[fieldName].add(value);
          }
        });
      }
    });
  });

  // Convert the sets to arrays for the final customFilter object
  const customFilter: Record<string, string[]> = {};
  Object.entries(customFieldsMap).forEach(([fieldName, valuesSet]) => {
    customFilter[fieldName] = Array.from(valuesSet);
  });

  const uniqueRegions = Object.values(
    partners
      .flatMap((partner) => partner.regions || [])
      .reduce(
        (acc: { [key: string]: { label: string; value: number } }, region) => ({
          ...acc,
          [String(region.ID)]: { label: region.Name, value: region.ID },
        }),
        {}
      )
  );

  const uniquePartnerTypes = Object.values(
    partners
      .flatMap((partner) => partner.tags || [])
      .reduce<{ [key: string]: { label: string; value: number } }>(
        (acc, type) =>
          type.ID &&
          type.Name &&
          !type.Name.toLowerCase().includes("directory listing")
            ? {
                ...acc,
                [String(type.ID)]: { label: type.Name, value: type.ID },
              }
            : acc,
        {}
      )
  );

  return (
    <div
      style={{
        minHeight: "100vh",
        background: token.colorBgLayout,
        padding: "40px 24px",
      }}
    >
      <div style={{ maxWidth: 1200, margin: "0 auto" }}>
        {/* Header Component */}
        <DirectoryHeader directory={directory} />

        {/* Divider line */}
        <div
          style={{
            borderTop: ".5px solid #f0f0f0",
            width: "100%",
            paddingBottom: "24px",
          }}
        />

        <Row gutter={[24, 24]}>
          {/* Left Column - Filters */}
          <Col xs={24} md={6}>
            <DirectoryFilters
              directory={directory}
              onFilterChange={handleFilterChange}
              initialFilters={filters}
              customFilter={customFilter}
              uniqueRegions={uniqueRegions}
              uniquePartnerTypes={uniquePartnerTypes}
            />
          </Col>

          {/* Right Column - Search and Partner Cards */}
          <Col xs={24} md={18} style={{ paddingTop: 34 }}>
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search partners"
              style={{
                marginBottom: 24,
                background: token.colorBgContainer,
                borderRadius: 6,
                height: 30,
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <PartnersCardList
              directory={directory}
              filteredPartners={filteredPartners}
              searchText={searchText}
              setSearchText={setSearchText}
              directoryId={directoryId}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
