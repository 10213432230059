import { AccessControlProvider } from "@refinedev/core";

import { QueryClient } from "@tanstack/react-query";

import {
  AUTH_ENABLED_RESOURCES,
  DEFINED_RESOURCES_TO_PERMISSION_RESOURCE_MAP,
  REFINE_ACTIONS_TO_PERMISSION_ACTION_MAP,
} from "@/constants/permissions";

import { API_BASE_URL } from "./data";
import globalAxiosInstance from "./globalAxiosProvider";

const queryClient = new QueryClient();

export const accessControlProvider: AccessControlProvider = {
  can: async ({ resource, action }) => {
    // Add type assertions to ensure resource and action are treated as strings
    const resourceKey = resource as string;
    const actionKey = action as string;

    const mappedResource =
      DEFINED_RESOURCES_TO_PERMISSION_RESOURCE_MAP[resourceKey] ?? resourceKey;

    if (!mappedResource || !AUTH_ENABLED_RESOURCES.includes(mappedResource)) {
      return {
        can: true,
        reason: "Resource is not enabled for access control",
      };
    }

    const mappedAction =
      REFINE_ACTIONS_TO_PERMISSION_ACTION_MAP[actionKey] ?? actionKey;

    const data = await queryClient.fetchQuery({
      queryKey: ["permissions", mappedResource, mappedAction],
      queryFn: async () => {
        const response = await globalAxiosInstance.get(
          `${API_BASE_URL}/permissions/check`,
          { params: { resource: mappedResource, action: mappedAction } }
        );
        return response.data;
      },
      staleTime: 5 * 60 * 1000, // Data considered fresh for 5 mins
      cacheTime: 10 * 60 * 1000, // Cache kept for 10 mins
    });

    return {
      can: data?.isAllowed ?? false,
      reason: data?.reason ?? "Unauthorized",
    };
  },

  options: {
    buttons: {
      hideIfUnauthorized: true,
    },
  },
};
